<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import TemplateGroup from "@/components/Templates/components/TemplateGroup.vue";

const store = useStore();
const templateGroups = computed(() => store.state.templateGroups);
const updateTemplates = computed(() => store.state.updateTemplates);
onMounted(() => {
  store.dispatch("getTemplateList");
});
</script>

<template>
  <section class="select-template">
    <header class="select-template-header">
      <h1>Select a template</h1>
    </header>
    <main class="select-template-main">
      <div class="template-group-list" v-if="updateTemplates">
        <template-group
          v-for="(item, index) in templateGroups"
          :key="index"
          :group="item"
        />
      </div>
    </main>
  </section>
</template>

<style lang="scss" scoped>
.select-template {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.select-template-main {
  flex: 1;
  overflow: auto;
}
.select-template-main::-webkit-scrollbar {
  width: 6px;
}
.select-template-main::-webkit-scrollbar-track {
  box-shadow: none;
}
.select-template-main::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    90deg,
    transparent 80%,
    #222a34 80%,
    #222a34 100%
  );
  outline: none;
}
.select-template-header {
  padding: 16px 0;

  h1 {
    font-family: "Lato";
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.36px;
    color: #003c6c;
    text-align: center;
    margin: 0;
  }
}

.select-template-main {
  overflow: auto;
}

.template-group:not(:last-child) {
  margin-bottom: 32px;
}
</style>
