<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import DateField from "@/components/MergeCodes/components/DateField.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const store = useStore();
const dateFormat = ref("M/d/yyyy");
const selectCode = (item) => {
  if (isDateField.value) {
    item.DateFormat = dateFormat.value;
  }
  store.commit("updateSelectedCode", item);
};
const selectedCode = computed(() => store.state.selectedCode);
const isDateField = computed(
  () => props.item.Type && props.item.Type === "DateTime"
);

const breadcrumbsItems = computed(() => {
  if (!props.item || !props.item.Name) return "";
  let breadcrumbs = props.item.Name.split(".");
  breadcrumbs.pop();
  if (breadcrumbs.length > 1) {
    breadcrumbs = " > " + breadcrumbs.join(" > ");
  } else if (breadcrumbs.length === 1) {
    breadcrumbs = ` > ${breadcrumbs[0]}`;
  } else {
    breadcrumbs = "";
  }
  return `${props.item.TabName}${breadcrumbs}`;
});

const itemName = computed(
  () => props.item && props.item.Name && props.item.Name.split(".").pop()
);

watch(
  () => dateFormat.value,
  () => {
    if (Object.keys(selectedCode.value).length) {
      store.commit(
        "updateSelectedCode",
        Object.assign(selectedCode.value, {
          DateFormat: dateFormat.value,
        })
      );
    }
  }
);
</script>

<template>
  <label class="most-used-codes__block">
    <input type="radio" name="code" @change="selectCode(item)" />
    <div class="most-used-codes__item">
      <p class="most-used-codes__category">{{ breadcrumbsItems }}</p>
      <h3 class="most-used-codes__name" v-if="!isDateField">{{ itemName }}</h3>
      <date-field v-else v-model="dateFormat" :item="item" :name="itemName" />
    </div>
  </label>
</template>

<style lang="scss" scoped>
.most-used-codes {
  &__block {
    display: block;
    width: 100%;

    input {
      display: none;

      &:checked {
        & + .most-used-codes__item {
          border-color: #0578d6;
          background-color: #cde4f7;

          h3 {
            font-weight: 700;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__item {
    border: 1px solid #eff2f6;
    border-radius: 4px;
    padding: 6px 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #eff2f6;
    }
  }
  &__category {
    font-family: "Lato";
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.1px;
    color: #95999d;
    margin: 0 0 2px 0;
  }
  &__name {
    font-family: "Lato";
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: #222a34;
    margin: 0;
  }
}
</style>
