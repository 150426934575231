<script setup>
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import Login from "@/components/Login.vue";
import TwoFactorAuthentication from "@/components/TwoFactorAuthentication.vue";
import Database from "@/components/Database/Database.vue";
import Templates from "@/components/Templates/Templates.vue";
import MergeCodes from "@/components/MergeCodes/MergeCodes.vue";
import { Modal } from "bootstrap";

const store = useStore();
const errorModal = ref(null);
const showLoader = computed(() => store.state.showLoader);
const isWelcomeScreen = computed(() => store.state.isWelcomeScreen);
const isLoginScreen = computed(() => store.state.isLoginScreen);
const isDBScreen = computed(() => store.state.isDBScreen);
const isTwoFactorAuthenticationScreen = computed(
  () => store.state.isTwoFactorAuthenticationScreen
);
const isTemplateScreen = computed(() => store.state.isTemplateScreen);
const isEditScreen = computed(() => store.state.isEditScreen);
const showLoginLayout = computed(() => store.state.showLoginLayout);
const errorMessage = computed(() => store.state.errorMessage);
const isUnsupportScreen = computed(() => store.state.isUnsupportedScreen);

watch(
  () => errorMessage.value,
  (newError) => {
    if (newError) {
      errorModal.value.show();
    }
  }
);

onMounted(() => {
  if (
    navigator.userAgent.indexOf("Trident") !== -1 ||
    navigator.userAgent.indexOf("Edg") !== -1
  ) {
    store.commit("showUnsupportedScreen");
  }
  setTimeout(() => {
    errorModal.value = new Modal("#errorModal", {
      keyboard: false,
    });

    errorModal.value._element.addEventListener("hidden.bs.modal", () => {
      store.commit("updateErrorMessage", "");
    });
  }, 0);
});
</script>

<template>
  <login-layout v-if="showLoginLayout">
    <h1 v-if="isWelcomeScreen">Welcome to Tall Emu Template Editor</h1>
    <h1 v-if="isUnsupportScreen">
      We noticed you're using an unsupported version of Microsoft Office. For
      more information, please consult the add-in supported versions
      <a
        class="btn btn-link"
        href="https://appsource.microsoft.com/en-us/product/office/WA200006097?tab=DetailsAndSupport"
        target="_blank"
        >here.</a
      >
    </h1>
    <Login v-if="isLoginScreen" />
    <Database v-if="isDBScreen" />
    <TwoFactorAuthentication v-if="isTwoFactorAuthenticationScreen" />
  </login-layout>
  <main-layout v-else>
    <Templates v-if="isTemplateScreen" />
    <MergeCodes v-if="isEditScreen" />
  </main-layout>
  <div
    v-if="showLoader"
    className="loader d-flex align-items-center justify-content-center"
  >
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  <div className="modal fade" id="errorModal" tabIndex="-1" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Error</h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">{{ errorMessage }}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

ul {
  margin: 0;
  padding: 0;
}

body {
  padding: 0 16px;
}

.btn {
  display: block;
  width: 100%;
  font-family: "Lato";
  font-size: 14px;
  line-height: 21px;
  border-radius: 4px;
  letter-spacing: -0.1px;
  text-align: center;
  padding: 4px 10px;
  outline: none;
  cursor: pointer;
  border: 1px solid transparent;
  text-decoration: none;

  &.btn-primary {
    background: #0578d6;
    color: #fff;
  }

  &.btn-primary-outlined {
    border-color: #0578d6;
    color: #0578d6;
    background-color: transparent;
  }

  &.btn-link {
    display: inline;
    color: #0578d6;
    border: none;
    width: auto;
    background-color: transparent;
    padding: 0;
  }

  &.btn-transparent {
    background-color: transparent;
    border-color: transparent;
    color: #535961;

    &:active {
      border-color: transparent;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.custom-field {
  position: relative;
  display: block;

  .custom-label {
    font-family: "Lato";
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: #535961;
    margin-bottom: 3px;
  }

  input {
    border: 1px solid #c8d5df;
    border-radius: 4px;
    width: 100%;
    font-family: "Lato";
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: #222a34;
    padding: 4px 8px;

    &:focus {
      outline: none;
    }

    &[type="password"] {
      &::-ms-reveal,
      &::-ms-clear {
        display: none;
      }
    }

    &[type="checkbox"] {
      display: none;

      &:checked {
        & + .checkbox-icon {
          border: 1px solid #0578d6;
          background-color: #0578d6;

          &:before {
            content: "";
            position: absolute;
            top: 2px;
            left: 5px;
            display: inline-block;
            transform: rotate(45deg);
            height: 8px;
            width: 4px;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
          }
        }
      }
    }
  }

  &.checkbox {
    display: flex;
    align-items: center;

    .custom-label {
      margin-bottom: 0;
      margin-left: 6px;
    }
  }

  .checkbox-icon {
    width: 16px;
    height: 16px;
    border-radius: 5px;
    border: 1px solid #c8d5df;
    display: block;
    cursor: pointer;
    position: relative;
  }

  .password-icon {
    position: absolute;
    right: 15px;
    top: 30px;
    cursor: pointer;
    width: 19px;
    height: 19px;

    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 1px;
      background-color: #535961;
      position: absolute;
      transform: rotate(45deg);
      top: 9px;
      left: 1px;
      opacity: 0;
    }

    &.show {
      &:before {
        opacity: 1;
      }
    }

    img {
      vertical-align: baseline;
    }
  }

  &.select {
    select {
      font-family: "Lato";
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.1px;
      color: #222a34;
      border: 1px solid #c8d5df;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .error {
    font-family: "Lato";
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px;
    color: #ec1b24;
    margin-top: 3px;
    display: inline-block;
  }
}

.modal-content {
  border-radius: 4px;
  max-width: 300px;
  margin: 0 auto;
}

.modal-header {
  justify-content: center;
  border: none;

  .modal-title {
    font-family: "Lato";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.32px;
    color: #222a34;
  }

  .btn-close {
    position: absolute;
    right: 16px;
    width: 9px;
    height: 9px;
    background-size: 9px;

    &:focus {
      box-shadow: none;
    }
  }
}

.modal-footer {
  border: none;

  .btn {
    width: auto;
  }
}

.login-layout {
  h1 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.36px;
    color: #003c6c;
    margin: 0;
  }
}

.popover {
  background-color: #003c6c;
  border-radius: 4px;
  border: none;

  --bs-popover-bg: #003c6c;
  --bs-popover-arrow-border: #003c6c;
}

.popover-body {
  font-family: "Lato";
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #ffffff;
  padding: 7px 12px;
}

.dropdown-item {
  font-family: "Lato";
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1px;
  color: #222a34;
}

.dropdown-item.danger {
  color: #c13b38;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
}
</style>
