<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const passwordVisible = ref(false);
const emailValue = ref("");
const passwordValue = ref("");
const passwordField = ref(null);
const showError = computed(() => store.state.showLoginError);
const showPass = () => {
  passwordVisible.value = !passwordVisible.value;
  passwordField.value.setAttribute(
    "type",
    passwordVisible.value ? "text" : "password"
  );
};
const updateLoginData = () => {
  store.commit("updateLoginData", {
    email: emailValue.value,
    password: passwordValue.value,
  });
  store.commit("updateLoginErrorStatus", false);
};

const submitLogin = () => {
  store.dispatch("loginUser");
};
</script>

<template>
  <section class="login-form">
    <h1>Log in to Tall Emu</h1>
    <form @submit="submitLogin">
      <label class="custom-field">
        <span class="custom-label">Email</span>
        <input
          v-model="emailValue"
          @input="updateLoginData"
          type="email"
          name="Email"
        />
      </label>
      <label class="custom-field">
        <span class="custom-label">Password</span>
        <input
          v-model="passwordValue"
          @input="updateLoginData"
          ref="passwordField"
          type="password"
          name="Password"
        />
        <span
          class="password-icon"
          :class="{ show: passwordVisible }"
          @click="showPass"
          ><img src="./../assets/ic-eye.jpg" alt="eye"
        /></span>
        <span v-if="showError" class="error"
          >The email and password you entered don't match. Please try
          again.</span
        >
      </label>
    </form>
    <a
      class="btn btn-link"
      href="https://crm.tallemu.com/Account/ForgotPassword"
      target="_blank"
      >Forgot password?</a
    >
  </section>
</template>

<style lang="scss" scoped>
.login-form {
  text-align: center;

  h1 {
    margin-bottom: 64px;
  }
  form {
    max-width: 259px;
    width: 100%;
    margin: 0 auto 24px;
    text-align: left;

    .custom-field {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
