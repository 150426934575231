import { createApp } from "vue";
import { vue3Debounce } from "vue-debounce";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheck,
  faMagnifyingGlass,
  faChevronDown,
  faChevronLeft,
  faEllipsisH,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/fonts.css";
import App from "./App.vue";
import store from "./store";

library.add(
  faCheck,
  faMagnifyingGlass,
  faChevronDown,
  faChevronLeft,
  faEllipsisH,
  faCheckCircle
);

window.Office.onReady(() => {
  createApp(App)
    .use(store)
    .directive("debounce", vue3Debounce({ lock: true }))
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");
});
