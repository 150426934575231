<script setup>
import moment from "moment";
import { Popover } from "bootstrap";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { loadTemplate } from "@/services/word";
// eslint-disable-next-line no-undef
const props = defineProps({
  templateItem: {
    type: Object,
    default: () => ({}),
  },
  sourceName: {
    type: String,
    default: "",
  },
});
const store = useStore();
const popoverItems = ref([]);
const updatedOn = computed(() =>
  moment(props.templateItem.UpdatedOn).format("MMMM DD, YYYY")
);
const setDefaultTemplate = () => {
  store.dispatch("setDefaultTemplate", {
    id: props.templateItem.Id,
    sourceName: props.sourceName,
  });
};

const deleteTemplate = () => {
  store.dispatch("deleteTemplate", props.templateItem.Id);
};

const selectTemplate = () => {
  store.commit(
    "updateSelectedTemplate",
    Object.assign(props.templateItem, { SourceName: props.sourceName })
  );
  store.dispatch("loadTemplate", props.templateItem.Id).then((data) => {
    if (data && data.Content) {
      loadTemplate(data.Content);
    }
  });
};

onMounted(() => {
  popoverItems.value.forEach((el) => {
    new Popover(el);
  });
});
</script>

<template>
  <label class="template-item">
    <input
      class="template-item__radio"
      @change="selectTemplate"
      type="radio"
      name="template-item"
      data-test="template-item-input"
    />
    <div class="template-item__block">
      <div class="template-item__top">
        <div class="template-item__title">
          <span
            v-if="templateItem.IsSystem"
            class="d-inline-block"
            tabindex="0"
            :ref="
              (el) => {
                popoverItems.push(el);
              }
            "
            data-bs-toggle="popover"
            data-bs-trigger="hover focus"
            data-bs-content="This is a Tall Emu template and cannot be directly edited. You can make a copy of this by choosing ‘Create New From Template’."
          >
            <img
              class="tooltip__icon"
              src="./../../../assets/footprint.png"
              alt="footprint icon"
            />
          </span>
          <p>{{ templateItem.Name }}</p>
        </div>
        <div class="template-menu">
          <div class="dropdown">
            <button
              class="template-menu__btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <font-awesome-icon icon="fa-ellipsis-h" />
            </button>
            <ul class="dropdown-menu">
              <li class="set-default-template-btn" @click="setDefaultTemplate">
                <a class="dropdown-item" href="#">Set as Default in CRM</a>
              </li>
              <li @click="deleteTemplate" class="delete-template-btn">
                <a class="dropdown-item danger" href="#">Delete</a>
              </li>
            </ul>
          </div>
          <div class="template-menu__popup"></div>
        </div>
      </div>
      <div class="template-item__date">
        Updated: {{ updatedOn }}
        <span
          v-if="templateItem.IsDefault"
          class="d-inline-block"
          tabindex="0"
          :ref="
            (el) => {
              popoverItems.push(el);
            }
          "
          data-bs-toggle="popover"
          data-bs-trigger="hover focus"
          data-bs-content="This is the default template that will be used in CRM."
        >
          <span class="template-item__default"
            ><font-awesome-icon icon="fa-check-circle"
          /></span>
        </span>
      </div>
    </div>
  </label>
</template>

<style lang="scss" scoped>
.template-item {
  display: block;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &__block {
    border: 1px solid #eff2f6;
    border-radius: 4px;
    padding: 5px 15px;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f7fd;

      .template-menu__btn {
        z-index: 1;
        opacity: 1;
      }
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  &__title {
    display: flex;
    align-items: center;

    img {
      margin-right: 4px;
    }
    p {
      font-family: "Lato";
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.1px;
      color: #222a34;
      margin: 0;
    }
  }
  &__date {
    font-family: "Lato";
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.1px;
    color: #95999d;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  &__default {
    cursor: pointer;
    color: #3abc8a;
  }
  &__radio {
    display: none;

    &:checked {
      & + .template-item__block {
        border: 1px solid #0578d6;
        background-color: #cde4f7;
      }
    }
  }
}
.template-menu {
  &__btn {
    color: #95999d;
    opacity: 0;
    position: relative;
    z-index: -100;
    transition: opacity 0.3s;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
</style>
