<script setup>
import { useStore } from "vuex";
import { ref, computed } from "vue";

const store = useStore();
const isWelcomeScreen = computed(() => store.state.isWelcomeScreen);
const isLoginScreen = computed(() => store.state.isLoginScreen);
const isDBScreen = computed(() => store.state.isDBScreen);
const isTwoFactorAuthenticationScreen = computed(
  () => store.state.isTwoFactorAuthenticationScreen
);
const isShowBtnLogin = computed(() =>
  isDBScreen.value || isTwoFactorAuthenticationScreen.value ? false : true
);
const loginLoading = ref(false);

const loginEvent = () => {
  if (isWelcomeScreen.value) {
    store.commit("showLoginScreen");
  } else if (isLoginScreen.value) {
    if (loginLoading.value) return;
    loginLoading.value = true;
    store.dispatch("loginUser").then(() => {
      loginLoading.value = false;
    });
  }
};

const backToWelcome = () => {
  store.commit("showWelcomeScreen");
};
</script>

<template>
  <section
    class="login-layout"
    :class="{ database: isDBScreen || isTwoFactorAuthenticationScreen }"
  >
    <header class="header">
      <img
        v-if="!isTwoFactorAuthenticationScreen"
        width="242"
        height="53"
        src="../assets/TallEmuLogo.png"
        alt="Tall Emu Logo"
        title="Tall Emu Logo"
      />
      <button v-if="isLoginScreen" class="btn btn-link" @click="backToWelcome">
        <font-awesome-icon icon="fa-chevron-left" /> Back
      </button>
    </header>
    <main>
      <slot />
    </main>
    <footer v-if="isShowBtnLogin">
      <button
        class="btn btn-primary"
        :disabled="loginLoading"
        @click="loginEvent"
      >
        Log In
      </button>
      <a
        v-if="isWelcomeScreen"
        href="https://tallemucrm.com/14-days-free-crm/"
        target="_blank"
        id="createAccountBtn"
        class="btn btn-primary-outlined"
        >Create An Account</a
      >
    </footer>
  </section>
</template>

<style lang="scss" scoped>
.login-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.database {
    display: block;
  }

  .header {
    padding-top: 40px;

    img {
      display: block;
      margin: 0 auto;
    }
    .btn {
      margin-top: 24px;
    }
  }

  footer {
    padding: 16px 0;

    .btn:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
