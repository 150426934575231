import { createStore } from "vuex";
import API from "@/services/api";
const api = new API();
export default createStore({
  state: {
    accessToken: "",
    baseURL: "",
    showLoader: false,
    isWelcomeScreen: true,
    isLoginScreen: false,
    isDBScreen: false,
    isTemplateScreen: true,
    isEditScreen: false,
    isUnsupportedScreen: false,
    isTwoFactorAuthenticationScreen: false,
    showLoginLayout: true,
    successSave: true,
    databaseLoading: false,
    showLoginError: false,
    updateTemplates: true,
    databaseList: [],
    templateGroups: [],
    selectedTemplate: {},
    loadedTemplate: {},
    mergeCodes: [],
    mergeCodesFilter: [],
    selectedCode: {},
    errorMessage: "",
    loginData: {
      email: "",
      password: "",
    },
    mostUsed: [],
  },
  getters: {
    createTemplateButtonDisabled(state) {
      return Object.keys(state.selectedTemplate).length === 0;
    },
    insertButtonDisabled(state) {
      return Object.keys(state.selectedCode).length === 0;
    },
    editTemplateButtonDisabled(state) {
      return (
        Object.keys(state.selectedTemplate).length === 0 ||
        state.selectedTemplate.TallEmu
      );
    },
    getAxiosConfig(state) {
      return {
        headers: { Authorization: `Bearer ${state.accessToken}` },
      };
    },
  },
  mutations: {
    updateAccessToken(state, token) {
      state.accessToken = token;
    },
    updateLoginLayoutStatus(state, status) {
      state.showLoginLayout = status;
    },
    updateTemplatesStatus(state, status) {
      state.updateTemplates = status;
    },
    updateLoginErrorStatus(state, status) {
      state.showLoginError = status;
    },
    updateLoadingStatus(state, status) {
      state.showLoader = status;
    },
    updateDatabaseLoadingStatus(state, status) {
      state.databaseLoading = status;
    },
    showWelcomeScreen(state) {
      state.showLoginLayout = true;
      state.isWelcomeScreen = true;
      state.isLoginScreen = false;
      state.isDBScreen = false;
      state.isEditScreen = true;
      state.isTemplateScreen = false;
      state.isTwoFactorAuthenticationScreen = false;
      state.isUnsupportedScreen = false;
    },
    showLoginScreen(state) {
      state.showLoginLayout = true;
      state.isWelcomeScreen = false;
      state.isLoginScreen = true;
      state.isDBScreen = false;
      state.isTemplateScreen = false;
      state.isEditScreen = false;
      state.showLoginError = false;
      state.isTwoFactorAuthenticationScreen = false;
      state.isUnsupportedScreen = false;
    },
    showDBScreen(state) {
      state.selectedTemplate = {};
      state.showLoginLayout = true;
      state.isWelcomeScreen = false;
      state.isLoginScreen = false;
      state.isDBScreen = true;
      state.isTwoFactorAuthenticationScreen = false;
      state.isUnsupportedScreen = false;
    },
    showEditScreen(state) {
      state.isEditScreen = true;
      state.isTemplateScreen = false;
    },
    showTemplateScreen(state) {
      state.isWelcomeScreen = false;
      state.isLoginScreen = false;
      state.isEditScreen = false;
      state.isTemplateScreen = true;
      state.isTwoFactorAuthenticationScreen = false;
      state.isUnsupportedScreen = false;
    },
    showUnsupportedScreen(state) {
      state.isWelcomeScreen = false;
      state.isLoginScreen = false;
      state.isEditScreen = false;
      state.isTemplateScreen = false;
      state.isUnsupportedScreen = true;
    },
    showTwoFactorAuthenticationScreen(state) {
      state.isWelcomeScreen = false;
      state.isLoginScreen = false;
      state.isEditScreen = false;
      state.isTemplateScreen = false;
      state.isDBScreen = false;
      state.isTwoFactorAuthenticationScreen = true;
    },
    toggleLoginLayout(state, status) {
      state.showLoginLayout = status;
    },
    setDatabaseList(state, newList) {
      state.databaseList = newList;
    },
    setTemplateGroups(state, newList) {
      state.templateGroups = newList;
    },
    updateSelectedTemplate(state, newTemplate) {
      state.selectedTemplate = newTemplate;
    },
    updateLoadedTemplate(state, newTemplate) {
      state.loadedTemplate = newTemplate;
    },
    updateSelectedCode(state, newCode) {
      state.selectedCode = newCode;
    },
    updateLoginData(state, newData) {
      state.loginData = newData;
    },
    setMostUsedCodes(state, newCodes) {
      state.mostUsedCodes = newCodes;
    },
    setMergeCodes(state, newCodes) {
      state.mergeCodes = newCodes;
      state.mergeCodesFilter = newCodes;
    },
    setMergeCodesFilter(state, newCodes) {
      state.mergeCodesFilter = newCodes;
    },
    updateErrorMessage(state, newMessage) {
      state.errorMessage = newMessage;
    },
    updateMostUsedData(state, data) {
      if (Array.isArray(data)) {
        state.mostUsed = data;
      } else {
        state.mostUsed.unshift(data);
      }
    },
    checkAuth(state, error) {
      const response = error.response;
      const status = response && response.status;
      const statusText = response && response.statusText;
      const errorMessage =
        typeof error === "string" ? error : "Some error has occurred";
      if (status === 401 && statusText === "Unauthorized") {
        if (state.isLoginScreen) {
          state.showLoginError = true;
        } else {
          state.showLoginLayout = true;
          state.isWelcomeScreen = false;
          state.isLoginScreen = true;
          state.isDBScreen = false;
          state.isTemplateScreen = false;
          state.isEditScreen = false;
          state.isUnsupportedScreen = false;
          state.showLoginError = false;
        }
      } else {
        if (response && response.data && response.data.Message) {
          state.errorMessage = response.data.Message;
        } else {
          state.errorMessage = errorMessage;
        }
      }
    },
  },
  actions: {
    loginUser({ state, commit, dispatch }) {
      return api
        .post("api/Login", state.loginData)
        .then((response) => {
          commit("updateAccessToken", response.data.token);
          dispatch("getDatabaseList").then((data) => {
            if (Array.isArray(data)) {
              if (data.length === 1) {
                dispatch("selectDB", {
                  databaseId: data[0].Id,
                  isPersistentDb: true,
                });
              } else if (data.length > 1) {
                commit("showDBScreen");
              }
            }
          });
        })
        .catch((error) => {
          commit("checkAuth", error);
        });
    },
    logoutUser({ commit, getters }) {
      return api
        .get("api/Logout", getters.getAxiosConfig)
        .then(() => {
          commit("showLoginScreen");
          commit("updateAccessToken", "");
        })
        .catch((error) => {
          commit("checkAuth", error);
        });
    },
    getDatabaseList({ state, commit }, getPersistentOnly = true) {
      commit("updateLoadingStatus", true);
      return api
        .get("api/GetAvailableDatabases", {
          headers: { Authorization: `Bearer ${state.accessToken}` },
          params: {
            getPersistentOnly,
          },
        })
        .then((response) => {
          commit("updateLoadingStatus", false);
          commit("setDatabaseList", response.data);
          return response.data;
        })
        .catch((error) => {
          commit("updateLoadingStatus", false);
          commit("checkAuth", error);
          return error;
        });
    },
    selectDB({ commit, getters, dispatch }, data) {
      commit("updateDatabaseLoadingStatus", true);
      commit("updateLoadingStatus", true);
      api
        .post("api/SelectDB", data, getters.getAxiosConfig)
        .then((response) => {
          commit("updateAccessToken", response.data.token);
          if (response.data.status === "TwoFactorAuthenticationRequired") {
            dispatch("getTwoFactorAuthentication").then(() => {
              commit("updateLoadingStatus", false);
              commit("updateDatabaseLoadingStatus", false);
              commit("showTwoFactorAuthenticationScreen");
            });
          } else {
            commit("showTemplateScreen");
            commit("toggleLoginLayout", false);
            commit("updateLoadingStatus", false);
            commit("updateDatabaseLoadingStatus", false);
          }
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
          commit("updateDatabaseLoadingStatus", false);
        });
    },
    getTemplateList({ commit, getters }) {
      commit("updateLoadingStatus", true);
      return api
        .get("api/GetTemplates", getters.getAxiosConfig)
        .then((response) => {
          commit("setTemplateGroups", response.data);
          commit("updateLoadingStatus", false);
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
        });
    },
    setDefaultTemplate({ getters, commit, dispatch }, data) {
      commit("updateLoadingStatus", true);
      api
        .post(
          "api/SetDefaultTemplate",
          {
            id: data.id,
            entity: data.sourceName,
          },
          getters.getAxiosConfig
        )
        .then(() => {
          dispatch("getTemplateList").then(() => {
            commit("updateTemplatesStatus", false);
            setTimeout(() => {
              commit("updateTemplatesStatus", true);
            }, 0);
          });
          commit("updateLoadingStatus", false);
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
        });
    },
    deleteTemplate({ getters, commit, dispatch }, id) {
      commit("updateLoadingStatus", true);
      api
        .delete("api/DeleteTemplate/" + id, getters.getAxiosConfig)
        .then(() => {
          dispatch("getTemplateList");
          commit("updateLoadingStatus", false);
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
        });
    },
    loadTemplate({ getters, commit }, id) {
      commit("updateLoadingStatus", true);
      return api
        .get("api/GetTemplate/" + id, getters.getAxiosConfig)
        .then((response) => {
          commit("updateLoadingStatus", false);
          commit("updateLoadedTemplate", response.data);
          return response.data;
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
        });
    },
    createTemplate({ state, getters, commit }, name) {
      commit("updateLoadingStatus", true);
      return api
        .post(
          "api/CopyTemplate",
          {
            id: state.selectedTemplate.Id,
            newName: name,
          },
          getters.getAxiosConfig
        )
        .then((response) => {
          commit("updateSelectedTemplate", response.data);
          commit("updateLoadingStatus", false);
          commit("showEditScreen");
          return response.data;
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
        });
    },
    getMergeCodes({ state, getters, commit }, payload) {
      const name =
        (payload && payload.Entity) || state.selectedTemplate.SourceName;
      const child = !!payload;
      const prefix = (payload && payload.Name) || " ";
      commit("updateLoadingStatus", true);
      api
        .get(
          `api/GetEntityProperties/${name}/${child}/${prefix}`,
          getters.getAxiosConfig
        )
        .then((response) => {
          response.data = response.data.map((data) => {
            data.Items = data.Items.map((item) => {
              if (item.Type && item.Type === "Reference") {
                item.Opened = false;
              }
              return {
                ...item,
              };
            });
            return {
              ...data,
            };
          });
          if (!child) {
            commit("setMergeCodes", response.data);
          } else {
            payload.Items = response.data[0].Items;
          }
          commit("updateLoadingStatus", false);
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
        });
    },
    saveAndUpload({ state, getters, commit }, base64Data) {
      commit("updateLoadingStatus", true);
      return api
        .patch(
          "api/UpdateTemplate/" + state.loadedTemplate.Id,
          {
            ...state.loadedTemplate,
            Content: base64Data,
          },
          getters.getAxiosConfig
        )
        .then(() => {
          state.successSave = true;
          commit("updateLoadingStatus", false);
          return true;
        })
        .catch((error) => {
          state.successSave = false;
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
          return false;
        });
    },
    getTwoFactorAuthentication({ commit, getters }) {
      return api
        .get("api/TwoFactorAuthentication", getters.getAxiosConfig)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          commit("checkAuth", error);
        });
    },
    twoFactorAuthentication({ getters, commit }, data) {
      api
        .post("api/TwoFactorAuthentication", data, getters.getAxiosConfig)
        .then((response) => {
          if (response.data.token) {
            commit("updateAccessToken", response.data.token);
            commit("toggleLoginLayout", false);
            commit("showTemplateScreen");
          } else {
            commit("checkAuth", response.data.errorMessage);
          }
        })
        .catch((error) => {
          commit("checkAuth", error);
          commit("updateLoadingStatus", false);
          commit("updateDatabaseLoadingStatus", false);
        });
    },
    regenerateTwoFactorAuthentication({ getters, commit }) {
      api
        .post("api/Regenerate2FACode", {}, getters.getAxiosConfig)
        .then((response) => {
          console.log("Regenerate2FACode", response);
        })
        .catch((error) => {
          commit("checkAuth", error);
        });
    },
  },
});
