<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import DatabaseItem from "./components/DatabaseItem.vue";

const store = useStore();
const isPersistentDb = ref(false);
const databaseLoading = computed(() => store.state.databaseLoading);
const databaseList = computed(() => store.state.databaseList);

const selectDB = (item) => {
  if (!item.IsActive || databaseLoading.value) return;
  const prevDB = databaseList.value.find((el) => el.IsSelected);
  prevDB && (prevDB.IsSelected = false);
  item.IsSelected = true;
  const data = {
    databaseId: item.Id,
    isPersistentDb: isPersistentDb.value,
  };

  store.dispatch("selectDB", data);
};
</script>

<template>
  <section class="database">
    <h1>Select database</h1>
    <label class="custom-field checkbox">
      <input v-model="isPersistentDb" type="checkbox" />
      <span class="checkbox-icon"></span>
      <span class="custom-label">Always log me in to this company file</span>
    </label>
    <div class="database-list">
      <database-item
        v-for="item in databaseList"
        :key="item.Id"
        :item="item"
        @click="selectDB(item)"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.database {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .custom-field {
    margin-bottom: 24px;
  }
}
.database-list {
  max-width: 318px;
  width: 100%;
}
</style>
