<script setup>
import moment from "moment";
import { computed } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  name: {
    type: String,
    default: "",
  },
  modelValue: String,
});
const dateFormat = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
const emit = defineEmits(["update:modelValue"]);
const getDate = (format) => {
  return moment(new Date()).format(format);
};
</script>

<template>
  <div class="options">
    <div class="options-date">
      <p class="options-date__title" v-html="name"></p>
      <label class="custom-field select">
        <span class="custom-label">Format</span>
        <select class="form-select" v-model="dateFormat">
          <option value="M/d/yyyy">{{ getDate("M/D/yyyy") }}</option>
          <option value="dddd, MMMM d, yyyy">
            {{ getDate("dddd, MMMM D, yyyy") }}
          </option>
          <option value="MMMM d, yyyy">
            {{ getDate("MMMM D, yyyy") }}
          </option>
          <option value="M/d/yy">{{ getDate("M/D/YY") }}</option>
          <option value="yyyy-MM-dd">{{ getDate("yyyy-MM-DD") }}</option>
          <option value="d-MMM-yy">{{ getDate("D-MMM-YY") }}</option>
          <option value="M.d.yyyy">{{ getDate("M.D.yyyy") }}</option>
          <option value="MMM. d, yy">
            {{ getDate("MMM. D, YY") }}
          </option>
          <option value="d MMMM yyyy">{{ getDate("D MMMM yyyy") }}</option>
          <option value="MMMM yy">{{ getDate("MMMM YY") }}</option>
          <option value="MMM-yy">{{ getDate("MMM-YY") }}</option>
          <option value="M/d/yyyy h:mm am/pm">
            {{ getDate("M/D/yyyy LT") }}
          </option>
          <option value="M/d/yyyy h:mm:ss am/pm">
            {{ getDate("M/D/yyyy LTS") }}
          </option>
          <option value="h:mm am/pm">{{ getDate("LT") }}</option>
          <option value="h:mm:ss am/pm">{{ getDate("LTS") }}</option>
          <option value="HH:mm">{{ getDate("HH:mm") }}</option>
          <option value="HH:mm:ss">{{ getDate("HH:mm:ss") }}</option>
        </select>
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-field.select {
  display: flex;
  align-items: center;

  .custom-label {
    font-family: "Lato";
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.1px;
    color: #535961;
    margin-right: 9px;
  }
}
</style>
