<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import MostUsedItem from "@/components/MergeCodes/components/MostUsedItem.vue";

const store = useStore();
const mostUsedCodes = computed(() => store.state.mostUsed);

onMounted(() => {
  let mostUsedData = window.localStorage.getItem("mostUsed");
  if (mostUsedData) {
    mostUsedData = JSON.parse(mostUsedData);
    mostUsedData = mostUsedData.sort(function (a, b) {
      return b.Count - a.Count || a.Name.localeCompare(b.Name);
    });
    store.commit("updateMostUsedData", mostUsedData);
  }
});
</script>

<template>
  <div class="most-used-codes">
    <most-used-item
      v-for="item in mostUsedCodes"
      :key="item.Name"
      :item="item"
    />
  </div>
</template>
