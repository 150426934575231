<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const digitCodeValue = ref("");
const isRememberDevice = ref(false);

const submitAuthentication = () => {
  const data = {
    Code: digitCodeValue.value,
    RememberDevice: isRememberDevice.value,
  };
  store.dispatch("twoFactorAuthentication", data);
};

const reGenerateCode = () => {
  store.dispatch("regenerateTwoFactorAuthentication");
};
</script>

<template>
  <section class="login-form">
    <div class="container">
      <div class="te-panel te-panel-md">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="te-panel-header clearfix">
              <div class="te-panel-logo">
                <img
                  width="143"
                  height="32"
                  src="../assets/TallEmuLogo.png"
                  alt="Tall Emu Logo"
                  title="Tall Emu Logo"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="te-panel-center">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="te-panel-title">
                    <h1>Complete logging in with two factor authentication</h1>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="te-panel-footer">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="te-panel-form">
                          <div class="form-group form-group-sm">
                            <div class="col-sm-12">
                              <div class="text-center">
                                <button
                                  type="submit"
                                  name="btnLogin"
                                  class="btn te-panel-button"
                                  @click="reGenerateCode"
                                  data-test="reGenerateCode"
                                >
                                  Re-generate Code
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <div class="te-panel-form">
                    <form
                      class="form-horizontal"
                      @submit.prevent="submitAuthentication"
                    >
                      <div class="form-group form-group-sm">
                        <div class="col-sm-12 mb-3">
                          <label class="te-panel-label" for="Code"
                            >Enter the 6-digit code provided by EMAIL</label
                          >

                          <input
                            id="Code"
                            name="Code"
                            class="te-panel-input"
                            type="text"
                            v-model="digitCodeValue"
                            placeholder="Enter the 6-digit code"
                            autocomplete="off"
                          />
                        </div>
                        <div class="col-sm-12">
                          <input
                            type="checkbox"
                            class="remember-me"
                            id="isRememberDevice"
                            name="isRememberDevice"
                            v-model="isRememberDevice"
                          />
                          <label for="isRememberDevice"
                            >Remember me for 30 days</label
                          >
                        </div>
                      </div>
                      <div class="form-group form-group-sm">
                        <div class="col-sm-12">
                          <div class="text-center">
                            <button
                              type="submit"
                              name="btnLogin"
                              class="btn te-panel-button"
                            >
                              Complete
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.te-panel-logo {
  float: right;
}
.te-panel-input {
  padding: 0px 0px 0px 13px;
  width: 100%;
  height: 30px;
  background-color: #f9fbfe;
  border: 1.5px solid #d8d8d8;
  border-radius: 15px;
  outline: 0;
  box-shadow: 0px 0px 1px -2px #000;
}

.te-panel-input:focus {
  border: 1.5px solid rgba(62, 178, 229, 1);
}

.te-panel-label {
  margin-bottom: 5px;
  color: #004b7d;
  font-weight: bold;
}

.te-panel-link {
  color: #8f8f8f;
  &:hover {
    color: #3eb2e5;
  }
}

.te-panel-button,
.te-panel-link-button {
  margin: 0 auto;
  background: #0098df;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  height: 35px;
  width: auto;
  min-width: 110px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  outline: 0 !important;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 10px 5px rgba(0, 0, 0, 0.1);
}

.te-panel-button:hover,
.te-panel-link-button:hover,
.te-panel-button:disabled,
.te-panel-link-button:disabled,
.te-panel-button:disabled:hover,
.te-panel-link-button:disabled:hover {
  background: #00b2ff;
  color: #fff;
  transform: scale(1.1);
}

.te-panel-button:focus,
.te-panel-link-button:focus {
  color: #fff;
}

.te-panel-button:disabled {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: 0.65;
}

.te-panel {
  margin: 0 auto;
  min-width: 300px !important;
  padding: 15px;
  background-color: #fff;
}

@media (min-width: 768px) {
  .te-panel {
    margin-top: 20px;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 0 25px 0 rgba(185, 191, 214, 0.45),
      0 0 15px 0 rgba(0, 0, 0, 0.2);
  }
}

.te-panel-sm {
  max-width: 450px !important;
}

.te-panel-md {
  max-width: 600px !important;
}

.te-panel-lg {
  max-width: 900px !important;
}

.te-panel-xl {
  max-width: 1200px !important;
}

.te-panel-header {
  padding: 0px;
  margin: 0px;
}

.remember-me {
  position: absolute;
  z-index: -1;
  opacity: 0;
  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: #8f8f8f;
    font-size: 14px;
    font-weight: normal;
    &:hover {
      color: #3eb2e5;
      cursor: pointer;
    }
  }

  & + label::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #0097dc;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 5px;
  }

  &:checked + label::before {
    background-color: #0b76ef;
  }
}

@media (max-width: 767px) {
  .te-panel-header {
    padding: 0 0 5px 0;
  }
}

.te-panel-center {
  margin: 0 auto;
}

@media (max-width: 767px) {
  .te-panel-center {
    width: 100%;
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .te-panel-center {
    width: 100%;
    padding: 25px;
  }
}

.te-panel-title {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  h1 {
    font-size: 18px;
    font-weight: bold;
    color: #004b7d;
  }
}

.te-panel-description {
  margin: 20px 0 10px 0px;
}

.te-panel-form {
  margin: 0;
  padding: 0;
  position: relative;
  .form-group {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .te-panel-form {
    margin: 15px 0 0 0;
  }
}

@media (min-width: 768px) {
  .te-panel-form {
    margin: 25px 0 0 0;
  }
}
</style>
