<script setup>
import { ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import CodeItem from "@/components/MergeCodes/components/CodeItem.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  tab: {
    type: Object,
    default: () => ({}),
  },
});

const store = useStore();
const searchValue = ref("");
const items = ref([]);

const searchCode = () => {
  const filter = (items) => {
    return items.filter((item) => {
      item.Match = item.Name;
      if (!item.Type || (item.Type && item.Type !== "Reference")) {
        const indexOf = item.Name.toLowerCase().indexOf(
          searchValue.value.toLowerCase()
        );
        const matched = indexOf !== -1;
        if (matched) {
          if (!searchValue.value) {
            item.Match = item.Name;
          } else {
            const matchedSubStr = item.Name.slice(
              indexOf,
              indexOf + searchValue.value.length
            );
            const beginOfName = item.Name.slice(0, indexOf);
            const endOfName = item.Name.slice(
              indexOf + searchValue.value.length,
              item.Name.length
            );
            item.Match = `${beginOfName}<strong>${matchedSubStr}</strong>${endOfName}`;
          }
        }
        return matched;
      } else if (item.Opened && item.Items) {
        item.Items = filter(item.Items);
        return item.Items.length > 0;
      } else {
        return !searchValue.value;
      }
    });
  };

  items.value = filter(props.tab.Items);

  store.commit("updateSelectedCode", {});
};

watch(
  () => searchValue.value,
  (newVal) => {
    if (!newVal) {
      store.commit("updateSelectedCode", {});
      searchCode();
    }
  }
);

onMounted(() => {
  items.value = props.tab?.Items || [];
});
</script>

<template>
  <div class="invoice-codes">
    <label class="custom-field search">
      <input
        v-model="searchValue"
        placeholder="Search for merge code..."
        type="search"
        name="searchCode"
        v-debounce:300ms="searchCode"
      />
      <div class="search__icon">
        <font-awesome-icon icon="fa-magnifying-glass" />
      </div>
    </label>
    <div class="accordion accordion-flush">
      <code-item
        v-for="item in items"
        :key="item.Name"
        :item="item"
        :tabName="tab.Label"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.invoice-codes {
  .search {
    margin-bottom: 16px;
    position: relative;
    &__icon {
      color: #535961;
      font-size: 14px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
    }

    input {
      padding-left: 30px;
    }
  }
}
</style>
