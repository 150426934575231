<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import MostUsed from "./components/MostUsed.vue";
import TabItem from "./components/TabItem.vue";

const store = useStore();
const mergeCodes = computed(() => store.state.mergeCodes);
onMounted(() => {
  store.dispatch("getMergeCodes");
});
</script>
<template>
  <section class="select-template">
    <header class="select-template-header">
      <h1>Insert Merge Codes</h1>
    </header>
    <main class="merge-codes">
      <ul class="nav nav-tabs" id="mergeCodesTabs" role="tablist">
        <li
          v-for="(tab, index) in mergeCodes"
          :key="index"
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            :class="{ active: index === 0 }"
            :id="tab.Entity + '-tab'"
            data-bs-toggle="tab"
            :data-bs-target="'#' + tab.Entity + '-tab-pane'"
            type="button"
            role="tab"
            :aria-controls="tab.Entity + '-tab-pane'"
            aria-selected="true"
          >
            {{ tab.Label }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="most-used-id"
            data-bs-toggle="tab"
            data-bs-target="#most-used-tab-pane"
            type="button"
            role="tab"
            aria-controls="most-used-tab-pane"
            aria-selected="true"
          >
            Most Used
          </button>
        </li>
      </ul>
      <div class="tab-content" id="mergeCodesContent">
        <div
          v-for="(tab, index) in mergeCodes"
          :key="index"
          class="tab-pane fade"
          :class="{ 'show active': index === 0 }"
          :id="tab.Entity + '-tab-pane'"
          role="tabpanel"
          :aria-labelledby="tab.Entity + '-tab'"
          tabindex="0"
        >
          <tab-item :tab="tab" />
        </div>
        <div
          class="tab-pane fade"
          id="most-used-tab-pane"
          role="tabpanel"
          aria-labelledby="most-used-id"
          tabindex="0"
        >
          <most-used />
        </div>
      </div>
    </main>
  </section>
</template>

<style lang="scss" scoped>
.select-template {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.merge-codes {
  flex: 1;
  overflow: auto;
  padding: 8px 16px 0;
  border-radius: 4px;
  border: 1px solid #c8d5df;
  display: flex;
  flex-direction: column;
}
.select-template-header {
  padding: 16px 0;

  h1 {
    font-family: "Lato";
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.36px;
    color: #003c6c;
    text-align: center;
    margin: 0;
  }
}
.select-template-main {
  overflow: auto;
}
.nav-tabs {
  gap: 8px;
  border: none;
  margin-bottom: 16px;
}
.nav-item {
  flex: 1;

  .nav-link {
    font-family: "Lato";
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: #535961;
    width: 100%;
    padding: 4px 10px;
    border: none;
    border-bottom: 3px solid transparent;
    white-space: nowrap;

    &.active {
      color: #0578d6;
      border-bottom-color: #0578d6;
    }
  }
}
.tab-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient(
      90deg,
      transparent 80%,
      #222a34 80%,
      #222a34 100%
    );
    outline: none;
  }
}
</style>
