<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import { insertField, getTemplate } from "@/services/word";

const store = useStore();
const createTemplateButtonDisabled = computed(
  () => store.getters.createTemplateButtonDisabled
);
const showLoader = computed(() => store.state.showLoader);
const insertButtonDisabled = computed(() => store.getters.insertButtonDisabled);
const showSaveAndUploadButton = ref(false);
const isTemplateScreen = computed(() => store.state.isTemplateScreen);
const successSave = computed(() => store.state.successSave);
const isEditScreen = computed(() => store.state.isEditScreen);
const editTemplateButtonDisabled = computed(
  () => store.getters.editTemplateButtonDisabled
);
const newTemplateName = ref("");
const createTemplateModal = ref(null);
const validationModal = ref(null);
const templateNameField = ref(null);
const backToChangeDB = () => {
  store.dispatch("getDatabaseList", false).then(() => {
    store.commit("showDBScreen");
  });
};
const createNewTemplate = () => {
  createTemplateModal.value.hide();
  store.dispatch("createTemplate", newTemplateName.value);
};
const editTemplate = () => {
  store.commit("showEditScreen");
};

const insertCode = () => {
  insertField(store.state.selectedCode);
  updateMostUsedItems();
  showSaveAndUploadButton.value = true;
};

const updateMostUsedItems = () => {
  const selectedCode = store.state.selectedCode;
  let mostUsedData = window.localStorage.getItem("mostUsed");
  mostUsedData = (mostUsedData && JSON.parse(mostUsedData)) || [];
  if (mostUsedData.length) {
    const codeExists = mostUsedData.find((el) => el.Name === selectedCode.Name);
    if (codeExists) {
      codeExists.Count++;
    } else {
      mostUsedData.push(Object.assign(selectedCode, { Count: 1 }));
    }
  } else {
    mostUsedData.push(Object.assign(selectedCode, { Count: 1 }));
  }
  window.localStorage.setItem("mostUsed", JSON.stringify(mostUsedData));
  store.commit("updateMostUsedData", mostUsedData);
};

const saveAndUpload = () => {
  getTemplate((template) => {
    store.dispatch("saveAndUpload", btoa(template)).then((response) => {
      response && validationModal.value.show();
    });
  });
};

const signOut = () => {
  store.dispatch("logoutUser");
};

onMounted(() => {
  setTimeout(() => {
    createTemplateModal.value = new Modal("#createFormTemplateModal", {
      keyboard: false,
    });
    validationModal.value = new Modal("#validationModal", {
      keyboard: false,
    });

    createTemplateModal.value._element.addEventListener(
      "shown.bs.modal",
      () => {
        templateNameField.value.focus();
      }
    );

    validationModal.value._element.addEventListener("hidden.bs.modal", () => {
      showSaveAndUploadButton.value = false;
      store.commit("showTemplateScreen");
    });
  }, 0);
});
</script>

<template>
  <div class="main-template">
    <header class="template-header">
      <img
        width="110"
        height="24"
        src="../assets/TallEmuLogo.png"
        alt="Tall Emu Logo"
        title="Tall Emu Logo"
      />
      <div class="dropdown">
        <button
          class="hamburger-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul class="dropdown-menu">
          <li @click="backToChangeDB">
            <a class="dropdown-item" href="#">Change Database</a>
          </li>
          <li>
            <a
              class="dropdown-item"
              target="_blank"
              href="https://help.tallemucrm.com/"
              >Help</a
            >
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="signOut">Sign Out</a>
          </li>
        </ul>
      </div>
    </header>
    <main>
      <slot />
    </main>
    <footer class="template-footer">
      <template v-if="isTemplateScreen">
        <button
          @click="editTemplate"
          :disabled="editTemplateButtonDisabled"
          class="btn btn-primary"
          data-test="editButton"
        >
          Edit Template
        </button>
        <button
          :disabled="createTemplateButtonDisabled"
          type="button"
          class="btn btn-primary-outlined"
          data-bs-toggle="modal"
          data-bs-target="#createFormTemplateModal"
        >
          Create New From Template
        </button>
      </template>
      <template v-if="isEditScreen">
        <button
          @click="insertCode"
          :disabled="insertButtonDisabled"
          class="btn btn-primary"
          data-test="insertButton"
        >
          Insert
        </button>
        <button
          v-if="showSaveAndUploadButton"
          @click="saveAndUpload"
          class="btn btn-primary-outlined"
        >
          Save and Upload to CRM
        </button>
      </template>
    </footer>
  </div>
  <div
    class="modal fade"
    id="createFormTemplateModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title" id="exampleModalLabel">
            Create New From Template
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label class="custom-field">
            <span class="custom-label">Give your new template a name</span>
            <input
              v-model="newTemplateName"
              ref="templateNameField"
              type="text"
              name="templateName"
            />
          </label>
        </div>
        <div class="modal-footer">
          <button
            @click="createNewTemplate"
            :disabled="showLoader"
            data-test="createButton"
            type="button"
            class="btn btn-primary"
          >
            Create
          </button>
          <button
            type="button"
            class="btn btn-transparent"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="validationModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">
            <template v-if="successSave"> Upload successful </template>
            <template v-else> Validation unsuccessful </template>
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="validation-icon"
            :class="{ success: successSave, warning: !successSave }"
          >
            <i v-if="successSave" class="fas fa-check-circle"></i>
            <i v-else class="fas fa-exclamation-circle"></i>
          </div>
          <div class="validation-text">
            <p v-if="successSave">
              Your template <span class="bold">User-Created-Invoice</span> has
              been saved and uploaded to your database.
            </p>
            <p v-else>
              Your template was saved, but it could not be uploaded because it
              failed validation due to incorrect merge code(s). Please check and
              try uploading again.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-template {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
  overflow: auto;
}

main::-webkit-scrollbar {
  width: 6px;
}

main::-webkit-scrollbar-track {
  box-shadow: none;
}

main::-webkit-scrollbar-thumb {
  background-image: linear-gradient(
    90deg,
    transparent 80%,
    #222a34 80%,
    #222a34 100%
  );
  outline: none;
}

.template-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.template-footer {
  min-height: 106px;
  padding: 16px 0;

  .btn:not(:last-child) {
    margin-bottom: 16px;
  }
}

.hamburger-button {
  width: 18px;
  height: 19px;
  padding: 2px 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #95999d;
  }
}
.validation-icon {
  margin-bottom: 8px;
  text-align: center;
  font-size: 24px;
  &.warning {
    color: #f2c216;
  }
  &.success {
    color: #3abc8a;
  }
}
.validation-text {
  font-family: "Lato";
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1px;
  color: #222a34;
  margin: 0;

  .bold {
    font-weight: 700;
  }
}
</style>
