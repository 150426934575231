function getType(type = "") {
  switch (type) {
    case "DateTime":
      return "Date";
    default:
      return "MergeField";
  }
}
function getValue(item = "") {
  switch (item.Type) {
    case "DateTime":
      return '\\@ "' + item.DateFormat + '"';
    default:
      return item.Name;
  }
}
async function insertField(selectedCode) {
  console.log(selectedCode);
  console.log(getValue(selectedCode));
  await window.Word.run(async (context) => {
    var range = context.document.getSelection().getRange();
    range.insertField(
      window.Word.InsertLocation.before,
      getType(selectedCode.Type),
      getValue(selectedCode),
      true
    );
    await context.sync();
  }).catch(function (error) {
    console.log("Error:", error);
  });
}

async function loadTemplate(base64Data) {
  await window.Word.run(async (context) => {
    const body = context.document.body;
    body.clear();
    body.insertFileFromBase64(base64Data, window.Word.InsertLocation.replace);
    await context.sync();
  }).catch(function (error) {
    console.log("Error:", error);
  });
}

function getSliceAsync(
  file,
  nextSlice,
  sliceCount,
  gotAllSlices,
  docdataSlices,
  slicesReceived,
  callback = null
) {
  file.getSliceAsync(nextSlice, function (sliceResult) {
    if (sliceResult.status == "succeeded") {
      if (!gotAllSlices) {
        // Failed to get all slices, no need to continue.
        return;
      }

      // Got one slice, store it in a temporary array.
      // (Or you can do something else, such as
      // send it to a third-party server.)
      docdataSlices[sliceResult.value.index] = sliceResult.value.data;
      if (++slicesReceived == sliceCount) {
        // All slices have been received.
        file.closeAsync();
        onGotAllSlices(docdataSlices, callback);
      } else {
        getSliceAsync(
          file,
          ++nextSlice,
          sliceCount,
          gotAllSlices,
          docdataSlices,
          slicesReceived
        );
      }
    } else {
      gotAllSlices = false;
      file.closeAsync();
      console.log("getSliceAsync Error:", sliceResult.error.message);
    }
  });
}

function onGotAllSlices(docdataSlices, callback = null) {
  let docdata = [];
  for (let i = 0; i < docdataSlices.length; i++) {
    docdata = docdata.concat(docdataSlices[i]);
  }

  let fileContent = new String();
  for (let j = 0; j < docdata.length; j++) {
    fileContent += String.fromCharCode(docdata[j]);
  }
  callback(fileContent);
}

async function getTemplate(callback = null) {
  window.Office.context.document.getFileAsync(
    window.Office.FileType.Compressed,
    { sliceSize: 65536 /*64 KB*/ },
    async function (result) {
      if (result.status == "succeeded") {
        // If the getFileAsync call succeeded, then
        // result.value will return a valid File Object.
        const myFile = result.value;
        const sliceCount = myFile.sliceCount;
        const docdataSlices = [];
        let slicesReceived = 0,
          gotAllSlices = true;
        console.log("File size:" + myFile.size + " #Slices: " + sliceCount);

        // Get the file slices.
        getSliceAsync(
          myFile,
          0,
          sliceCount,
          gotAllSlices,
          docdataSlices,
          slicesReceived,
          callback
        );
      } else {
        console.log("Error:", result.error.message);
      }
    }
  );
}

export { insertField, loadTemplate, getTemplate };
