<script setup>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import NestedItem from "@/components/MergeCodes/components/NestedItem.vue";
import DateField from "@/components/MergeCodes/components/DateField.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  tabName: {
    type: String,
    default: "",
  },
});

const store = useStore();
const dateFormat = ref("M/d/yyyy");
const isNestedItem = computed(
  () => props.item.Type && props.item.Type === "Reference"
);
const name = computed(() => props.item.Match || props.item.Name);
const selectedCode = computed(() => store.state.selectedCode);
const isDateField = computed(
  () => props.item.Type && props.item.Type === "DateTime"
);

const toggleAccordion = (item) => {
  item.Opened = !item.Opened;
  selectCode(item);
};

const selectCode = (item) => {
  if (isNestedItem.value) {
    if (!item.Items) {
      store.dispatch("getMergeCodes", item);
      store.commit("updateSelectedCode", {});
    }
  } else {
    if (isDateField.value) {
      item.DateFormat = dateFormat.value;
    }
    store.commit(
      "updateSelectedCode",
      Object.assign(item, {
        TabName: props.tabName,
      })
    );
  }
};

watch(
  () => dateFormat.value,
  () => {
    if (Object.keys(selectedCode.value).length) {
      store.commit(
        "updateSelectedCode",
        Object.assign(selectedCode.value, {
          DateFormat: dateFormat.value,
        })
      );
    }
  }
);
</script>

<template>
  <template v-if="isNestedItem">
    <div class="accordion-code">
      <div class="accordion-button__wrap">
        <button
          class="accordion-button code-item"
          :class="{ opened: item.Opened }"
          type="button"
          @click="toggleAccordion(item)"
          v-html="name"
        ></button>
        <font-awesome-icon icon="fa-chevron-down" />
      </div>
      <div class="accordion-body" v-if="item.Opened">
        <nested-item
          v-if="item.Items"
          :tab-name="tabName"
          :items="item.Items"
        />
      </div>
    </div>
  </template>
  <template v-else>
    <label class="code-label">
      <input type="radio" name="code" @change="selectCode(item)" />
      <div class="code-item">
        <span v-if="!isDateField" v-html="name"></span>
        <date-field v-model="dateFormat" v-else :item="item" :name="name" />
      </div>
    </label>
  </template>
</template>

<style lang="scss" scoped>
.accordion-body {
  padding: 0;
}
.accordion-button {
  font-family: "Lato";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #222a34;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  margin-bottom: 8px;
  &__wrap {
    position: relative;
  }
  &.opened {
    & + svg {
      transform: rotate(180deg);
    }
  }

  &::after {
    content: none;
  }

  & + svg {
    font-size: 12px;
    text-align: center;
    color: #95999d;
    background-image: none;
    transform: rotate(0deg);
    transition: transform 0.3s;
    position: absolute;
    right: 18px;
    top: 12px;
    z-index: 3;
  }
  &:focus {
    border: 1px solid #eff2f6;
    box-shadow: none;
  }
}
.code-item {
  font-family: "Lato";
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.1px;
  color: #222a34;
  padding: 6px 16px;
  border: 1px solid #eff2f6;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  .nested {
    color: #004b7d;
    font-weight: bold;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: #95999d;
    }
  }

  &:hover {
    background-color: #eff2f6;
  }
}
.code-label {
  display: block;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  input {
    display: none;

    &:checked {
      & + .code-item {
        border-color: #0578d6;
        background-color: #cde4f7;
        font-weight: 700;

        .main {
          display: none;
        }
        .options {
          display: block;
        }
      }
    }
  }
}
.options-date {
  &__title {
    font-family: "Lato";
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: #222a34;
    margin: 0 0 2px 0;
  }
}
</style>
